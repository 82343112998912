import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import store from "@/store"

export default (app) =>
  ErrorReporter.init({
    app,
    apiKey: process.env.VUE_APP_BUGSNAG_TOKEN,
    onError: function (event) {
      const accountId = store.state.accountId
      if (accountId) {
        event.setUser(accountId)
      }
    },
  })
