import { createApp, nextTick } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import initErrorReporting from "@/helpers/initErrorReporting"
import StoryblokHelperPlugin from "@soenergy/frontend-library/src/plugins/storyblokHelper"
import WHDEligibilityService from "@soenergy/frontend-library/src/services/WHDEligibilityService"

import "@soenergy/frontend-library/src/stylesheets/vendors/bulma.scss"
import "@soenergy/frontend-library/src/stylesheets/base.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_form.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_list.scss"

import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import * as CookieConsentPlugin from "@soenergy/frontend-library/src/services/CookieConsentPlugin"
import AnalyticsPlugin, {
  AnalyticsService,
  MixpanelTracker,
} from "@soenergy/frontend-library/src/plugins/analytics"
import { PROJECTS } from "@soenergy/frontend-library/src/config/projectRouting"
import MixpanelService from "@soenergy/frontend-library/src/services/Mixpanel"
import { runOnceCookieIsAllowed } from "@soenergy/frontend-library/src/services/CookiePreferences"
import { initDatadogPlugin } from "@soenergy/frontend-library/src/services/datadog"
import initDatadogIdentity from "@/helpers/initDatadogIdentity"

const app = createApp(App)

app.use(initDatadogPlugin())
app.use(store)
app.use(router)

initErrorReporting(app)
app.use(StoryblokHelperPlugin)
app.use(AnalyticsPlugin)

if (process.env.VUE_APP_USE_THIRD_PARTY_COOKIE_CONSENT === "true") {
  CookieConsentPlugin.init()
}
AuthenticationService.init({
  store,
  onSessionExpired: () => {
    store.dispatch("logout")
  },
})
runOnceCookieIsAllowed("performance", () => {
  MixpanelService.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    projectName: PROJECTS.Whd,
  })
  AnalyticsService.init({
    trackers: [MixpanelTracker],
    vueRouter: router,
    projectName: PROJECTS.Whd,
    nextTick: nextTick,
  })
  initDatadogIdentity()
})

WHDEligibilityService.init({ store })

app.mount("#app")
