<template>
  <div :class="getRadioClass">
    <div class="radio-group__form">
      <RadioButton
        v-bind="option"
        v-for="(option, key) in options"
        :key="key"
        :name="name"
        :has-error="hasError"
      />
      <slot />
    </div>
    <div
      v-if="errorMessage"
      :id="feedbackId"
      class="form-group__invalid-feedback"
      role="alert"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import RadioButton from "./RadioButton.vue"

export default {
  components: {
    RadioButton
  },
  provide() {
    return {
      radioGroup: this
    }
  },
  model: {
    prop: "selectedValue",
    event: "checked"
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    selectedValue: {
      type: [String, Object, Boolean],
      default: ""
    },
    options: {
      type: Array,
      default: null
    },
    errorMessage: {
      type: String,
      default: ""
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getRadioClass() {
      return {
        "radio-group": true,
        "radio-group--error": this.hasError
      }
    },
    feedbackId() {
      return `${this.name}-feedback`
    }
  },
  methods: {
    checked(checked) {
      this.$emit("checked", checked)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/components/_form.scss";

.radio-group {
  .radio-group__form {
    width: 100%;
  }
  &--error {
    .radio-group__form {
      ::v-deep .radio-button__input + .radio-button__label {
        border: 2px solid $danger-02;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        .radio-button__text {
          font-weight: $weight-medium;
        }
      }
    }
  }

  &__form {
    border: 2px solid transparent;
    display: inline-block;
  }
}
</style>
