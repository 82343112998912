<template>
  <div class="radio-button">
    <input
      :id="inputIdLocal"
      v-model="binding"
      :value="value"
      :disabled="disabled || null"
      class="radio-button__input"
      type="radio"
      :name="name"
      :aria-invalid="hasError || null"
    />
    <div
      class="radio-button__label"
      :class="{
        'radio-button__label--thin': disableLabelEmboldening,
        'radio-button__label--small': isLabelSmall,
        'radio-button__label--no-border': noBorder
      }"
    >
      <div v-if="badgeContent" class="radio-button__badge">
        {{ badgeContent }}
      </div>
      <label
        class="size-large radio-button__text"
        :class="{ 'radio-button__text--button-left': radioButtonLeft }"
        :for="inputIdLocal"
      >
        <slot>{{ label }}</slot>
        <!-- @slot Icon displayed when item is selected -->
        <slot v-if="isChecked" name="icon"></slot>
      </label>
    </div>

    <!-- @slot Optional description shown in the next line -->
    <div v-if="$slots.default" class="radio-button__description">
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
/**
 * `RadioButton` can be used on its, or in conjunction with `RadioGroup` accepting `v-model` and `options` as parameters.
 *
 * RadioGroup:
 *
 *  ~~~~
  <RadioGroup v-model="selectedValue">
    <RadioButton value="solar" label="Solar" input-id="radio-solar" />
    <RadioButton value="tidal" label="Tidal" input-id="radio-tidal" />
  </RadioGroup>
~~~~
 * RadioGroup with options:
 *
 *  ~~~~
<RadioGroup v-model="selectedValue" :options="options"/>
~~~~
 * Standalone:
 *
 *  ~~~~
  <RadioButton
    value="solar"
    label="Solar"
    input-id="radio-solar"
    v-model="selectedValue"
  />
  <RadioButton
    value="tidal"
    label="Tidal"
    input-id="radio-tidal"
    v-model="selectedValue"
  />
~~~~
 *
 * Group of radio buttons, should be contained by a `<fieldset>` with a descriptive `<legend>`, to ensure good screenreader support:
 *
 *  ~~~~
  <fieldset>
    <legend>Select source type:</legend>
    <RadioGroup v-model="selectedValue" :options="options"/>
  </fieldset>
~~~~
 **/

import { uidMixin } from "../../mixins/uidMixin"

export default {
  inject: {
    radioGroup: {
      default: null
    }
  },
  mixins: [uidMixin],
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    /* Currently selected value sent by v-model */
    modelValue: {
      type: [String, Object, Boolean],
      default: ""
    },
    /* Value when checked */
    value: {
      type: [String, Object, Boolean],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: null
    },
    inputId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disableLabelEmboldening: {
      type: Boolean,
      default: false
    },
    isLabelSmall: {
      type: Boolean,
      default: false
    },
    badgeContent: {
      type: String,
      default: null
    },
    radioButtonLeft: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    binding: {
      get() {
        return this.selectedGroupValue
      },
      set(value) {
        this.inGroup
          ? this.radioGroup.checked(value)
          : this.$emit("change", value)
      }
    },
    inGroup() {
      return Boolean(this.radioGroup)
    },
    selectedGroupValue() {
      return this.inGroup ? this.radioGroup.selectedValue : this.modelValue
    },
    isChecked() {
      return this.selectedGroupValue === this.value
    }
  }
}
</script>

<style lang="scss" scoped>
$radio-size: $size-6;
$expanded-touch-area: $spacing-4;

.radio-button {
  &__input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
  }
  &__input:focus + &__label {
    box-shadow: 0px 0px 0px 4px $focus;
    border: 1px solid $grey-900;
  }
  &__input:checked + &__label {
    border: 1px solid $grey-900;
    .radio-button__text {
      &:before {
        opacity: 1;
      }
    }
  }
  &__input:checked:not(:focus) + &__label {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }
  &__input:disabled + &__label {
    background: $disabled-01;
    box-shadow: 0px 0px 0px 0px $white;
    .radio-button__text {
      color: $grey-700;

      &:after {
        background-color: $disabled-01;
      }
    }
  }
  &__input:checked + &__label:not(.radio-button__label--thin) {
    .radio-button__text {
      font-weight: $weight-medium;
    }
  }

  &__input:focus + &__label:not(.radio-button__label--thin) {
    .radio-button__text {
      font-weight: $weight-medium;
    }
  }

  &__label:not(.radio-button__label--small) {
    .radio-button__text {
      font-size: $font-size-4;
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    margin: $space-3 0;
    border: 1px solid $grey-700;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: $space-2;
    background-color: $white;
  }

  &__input:checked:not(:focus) + &__label--no-border {
    box-shadow: none;
  }

  &__input:checked + &__label--no-border {
    border: none;
  }

  &__input:focus + &__label--no-border {
    box-shadow: none;
    border: none;
  }

  &__label--no-border {
    box-shadow: none;
    border: none;
    &:focus + &:checked {
      box-shadow: none;
      border: none;
    }

    & > .radio-button__text {
      &--button-left {
        padding: $space-1 $space-5 $space-1 $space-9;

        &:after {
          left: 0;
        }

        &:before {
          left: $space-1;
        }
      }
    }
  }

  &__text {
    cursor: pointer;
    display: inline-block;
    touch-action: manipulation;
    padding: $space-5 $space-12 $space-5 $space-4;
    font-weight: $weight-normal;
    font-size: $font-size-2;
    width: 100%;

    &:after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      right: $space-4;
      top: 50%;
      transform: translateY(-50%);

      border-radius: 50%;
      height: $space-6;
      width: $space-6;

      background: #ffffff;
      border: 1px solid $grey-700;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;

      width: $space-4;
      height: $space-4;

      right: $space-5;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;

      background: $green-400;
      border-radius: 50%;
      opacity: 0;
    }

    &--button-left {
      padding: $space-5 $space-4 $space-5 $space-11;

      &:after {
        left: $space-3;
      }

      &:before {
        left: $space-4;
      }
    }
  }

  &__description {
    padding-left: $space-4;
  }

  &__badge {
    background-color: $yellow-300;
    position: absolute;
    top: -($space-5);
    right: 0;
    border-radius: $space-6;
    padding: 2px $space-3 $space-1;
    font-size: $font-size-2;
    font-weight: $weight-medium;
  }
}
</style>
